import {SmallFooter} from '@nib-components/footer';
import {Props, withVariants} from '../WithVariants';
import {ApiaFooter} from './Apia';
import {IngFooter} from './Ing';
import {SuncorpFooter} from './Suncorp';
import {AAMIFooter} from './AAMI';
import {PricelineFooter} from './Priceline';
import {SeniorsFooter} from './Seniors';
import {RealFooter} from './Real';
import {DentalPassFooter} from './DentalPass';
import {CorporateFooter} from './Corporate';

const variants = {
  apia: ApiaFooter,
  ing: IngFooter,
  aami: AAMIFooter,
  suncorp: SuncorpFooter,
  priceline: PricelineFooter,
  seniors: SeniorsFooter,
  real: RealFooter,
  nibP2PWeb: DentalPassFooter,
  corporate: CorporateFooter
};

// nib to default to the SmallFooter from Mesh
export const Footer = withVariants(variants, SmallFooter);
export type Footer = Props<typeof variants>;
