import {captureMessage} from '@sentry/gatsby';
import {capitalizeFirstCharacter, getItemsFromDate, getMultiplier, stringlyTypeObjectValues} from './../../services/utils';
import {getHospitalProductName, getExtrasProductName} from './../session/selectors/productDetails';
import {ARHIFunnelPage} from './../../types/pages/index';
import {ARHIJoinState} from '../rootReducer';
import {AppThunk} from '../store';
import {getTotalPrice} from './../pricing/selectors';
import {getPolicyNumber, getKycStatus} from './../join/selectors';
import {getCoverStartDate} from './../session/selectors/financialDetails';
import {getPaymentStartDate, getPaymentFrequency, getPaymentMethod} from './../session/selectors/paymentDetails';
import {getExtrasId, getHospitalId, getExcess} from './../session/selectors/productSelection';
import {getPreviousFundDetails} from './../session/selectors/healthDetails';
import {getPhoneNumber, getPostcode, getState as getAddressState, getSuburb} from './../session/selectors/contactDetails';
import {
  getPolicyHolderDob,
  getPolicyHolderEmail,
  getScale,
  getPolicyHolderFirstName,
  getPolicyHolderLastName,
  getPolicyHolderTitle,
  getPolicyHolderGender,
  getPartnerGender,
  getPartnerFirstName,
  getPartnerLastName,
  getPartnerTitle
} from './../session/selectors/personalDetails';
import {getSessionId, getSessionState, getJoinSource} from './../session/selectors/index';
import {trackPageView, trackEvent} from '@nib/react-metrics-redux';

import {getIncomeTier} from '../session/selectors/governmentDetails';
import {TealiumBusiness} from '../../hooks/UseTealiumBusinessSegment';
import {SendQuoteFormValues} from '../../components/Forms/SendQuote/types';
import {OnCoverSummaryEventPayload, OnFactSheetClickArgs} from '../../components/CoverSummaryPanel/types';

export const getCommonSessionAttributes = (joinState: ARHIJoinState) => {
  return stringlyTypeObjectValues({
    channel: getJoinSource(joinState) || process.env.GATSBY_SOURCE_CHANNEL,
    attribution_partner: joinState.session.session?.attributionPartner
  });
};

// Join pages
export const trackJoinHealthUdo =
  (currentPage: ARHIFunnelPage, tealiumBusinessSegment: TealiumBusiness): AppThunk =>
  async (dispatch, getState) => {
    const arhiJoinState: ARHIJoinState = getState();
    const sessionState = getSessionState(arhiJoinState);

    if (sessionState) {
      const trackingData = stringlyTypeObjectValues({
        page_name: currentPage.name,
        page_type: 'quote_health',
        policy_health_hospital_excess: getExcess(arhiJoinState),
        policyholder_address_state: getAddressState(arhiJoinState),
        policyholder_birthmonth: getItemsFromDate(getPolicyHolderDob(arhiJoinState), 'month') || null,
        policyholder_birthyear: getItemsFromDate(getPolicyHolderDob(arhiJoinState), 'year') || null,
        policyholder_email: getPolicyHolderEmail(arhiJoinState),
        policyholder_phone: getPhoneNumber(arhiJoinState) || null,
        policyholder_scale: getScale(arhiJoinState)
      });

      const tealiumOptions = {
        site_name: tealiumBusinessSegment.siteName,
        site_section: `${capitalizeFirstCharacter(tealiumBusinessSegment.name)} - Quote`
      };

      const dataWithDefaults = {...tealiumOptions, ...trackingData, ...getCommonSessionAttributes(arhiJoinState)};

      dispatch(trackPageView(dataWithDefaults));
    } else {
      const error = '[AJW4] - Error tracking event "trackJoinHealthUdo" -- Mapping, store or cookie error';
      console.error(error);
      captureMessage(error, 'error');
    }
  };

export const trackJoinHealthCompleteUdo =
  (tealiumBusinessSegment: TealiumBusiness): AppThunk =>
  async (dispatch, getState) => {
    const arhiJoinState: ARHIJoinState = getState();
    const sessionState = arhiJoinState.session.session;
    const pricingState = arhiJoinState.pricing;
    const joinState = arhiJoinState.join;

    if (sessionState && pricingState && joinState) {
      const trackingData = stringlyTypeObjectValues({
        // General
        page_name: `${capitalizeFirstCharacter(tealiumBusinessSegment.name)} - Join - Confirmation`,
        page_type: 'join_health_complete',

        // Partner
        partner_customer_number: null,
        partner_gender: getPartnerGender(arhiJoinState),
        partner_name_first: getPartnerFirstName(arhiJoinState),
        partner_name_last: getPartnerLastName(arhiJoinState),
        partner_title: getPartnerTitle(arhiJoinState),

        // Policy holder
        policyholder_birthmonth: getItemsFromDate(getPolicyHolderDob(arhiJoinState), 'month') || null,
        policyholder_birthyear: getItemsFromDate(getPolicyHolderDob(arhiJoinState), 'year') || null,
        policyholder_email: getPolicyHolderEmail(arhiJoinState),
        policyholder_phone: getPhoneNumber(arhiJoinState) || null,
        policyholder_scale: getScale(arhiJoinState),
        policyholder_address_postcode: getPostcode(arhiJoinState) || '',
        policyholder_address_state: getAddressState(arhiJoinState) || '',
        policyholder_address_suburb: getSuburb(arhiJoinState) || '',
        policyholder_current_fund: getPreviousFundDetails(arhiJoinState).description || null,
        policyholder_current_insured: getPreviousFundDetails(arhiJoinState).previouslyHadHealthInsurance,
        policyholder_income_tier: getIncomeTier(arhiJoinState)?.toString() || '',
        policyholder_name_first: getPolicyHolderFirstName(arhiJoinState) || '',
        policyholder_name_last: getPolicyHolderLastName(arhiJoinState) || '',
        policyholder_title: getPolicyHolderTitle(arhiJoinState) || '',
        policyholder_gender: getPolicyHolderGender(arhiJoinState) || '',

        // Product
        policy_health_extras: null,
        policy_health_extras_id: getExtrasId(arhiJoinState),
        policy_health_hospital: null,
        policy_health_hospital_id: getHospitalId(arhiJoinState),
        policy_health_hospital_excess: getExcess(arhiJoinState),

        // Financial
        policy_health_customer_number: null,
        policy_date_start_payment: getPaymentStartDate(arhiJoinState) || '',
        policy_date_start: getCoverStartDate(arhiJoinState) || '',

        policy_health_policy_number: getPolicyNumber(arhiJoinState),
        policy_installment_amount: getTotalPrice(arhiJoinState),
        policy_installment_frequency: getPaymentFrequency(arhiJoinState) || '',
        policy_payment_method: getPaymentMethod(arhiJoinState) || '',
        policy_submitted: getPolicyNumber(arhiJoinState) ? true : false,
        policy_total_annualised_estimate: `${pricingState.totalPrice * getMultiplier(getPaymentFrequency(arhiJoinState))}`,
        policy_type: 'Health Insurance',

        // Offer
        quote_health_quote_number: null,
        quote_health_residential_status: null,
        quote_health_session_id: getSessionId(arhiJoinState),

        quote_health_motivator: null,
        site_name: tealiumBusinessSegment.siteName,
        site_section: `${capitalizeFirstCharacter(tealiumBusinessSegment.name)} - Join`
      });

      dispatch(trackPageView({...trackingData, ...getCommonSessionAttributes(arhiJoinState)}));
    } else {
      const error = '[AJW5] - Error tracking event "trackJoinHealthCompleteUdo" -- Mapping, store or cookie error';
      console.error(error);
      captureMessage(error, 'error');
    }
  };

export const trackJoinHealthFunnelEndUdo = (): AppThunk => async (dispatch, getState) => {
  const arhiJoinState: ARHIJoinState = getState();
  const joinState = arhiJoinState.join;

  if (joinState) {
    const trackingData = stringlyTypeObjectValues({
      event_name: 'health_funnel_end',
      tealium_event: 'health_funnel_end',
      page_name: 'Complete',
      policy_health_customer_number: null,
      policy_health_policy_number: getPolicyNumber(arhiJoinState),
      policy_submitted: getPolicyNumber(arhiJoinState) ? true : false,
      kyc_status: getKycStatus(arhiJoinState)
    });
    dispatch(trackEvent(trackingData.event_name, {...trackingData, ...getCommonSessionAttributes(arhiJoinState)}));
  } else {
    const error = '[AJW6] - Error tracking event "trackJoinHealthFunnelEndUdo" -- Mapping, store or cookie error';
    console.error(error);
    captureMessage(error, 'error');
  }
};

export const trackJoinHealthFunnelStart =
  (tealiumBusiness: TealiumBusiness, page: string): AppThunk =>
  async (dispatch, getState) => {
    const arhiJoinState: ARHIJoinState = getState();

    const trackingData = stringlyTypeObjectValues({
      event_name: 'health_funnel_start',
      tealium_event: 'health_funnel_start',
      page_name: page,
      quote_health_residential_status: 'Australian resident',
      business_segment: tealiumBusiness.segment,
      policy_health_fund: 'arhi',
      policy_type: 'health insurance',
      site_name: tealiumBusiness.siteName,
      site_section: 'ARHI - Join',
      page_type: 'quote_health'
    });

    dispatch(trackEvent(trackingData.event_name, {...trackingData, ...getCommonSessionAttributes(arhiJoinState)}));
  };

export const trackJoinHealthFunnelWelcomeSubmit = (tealiumBusiness: TealiumBusiness) => async (dispatch, getState) => {
  const arhiJoinState: ARHIJoinState = getState();

  const trackingData = stringlyTypeObjectValues({
    tealium_event: 'health_funnel_welcome_submit',
    event_name: 'health_funnel_welcome_submit',
    business_segment: tealiumBusiness.segment,
    quote_health_residential_status: 'Australian resident'
  });

  dispatch(trackEvent(trackingData.event_name, {...trackingData, ...getCommonSessionAttributes(arhiJoinState)}));
};

// Quote pages
export const trackQuoteHealthUdo =
  (currentPage: ARHIFunnelPage, tealiumBusinessSegment: TealiumBusiness): AppThunk =>
  async (dispatch, getState) => {
    const arhiJoinState: ARHIJoinState = getState();
    const sessionState = getSessionState(arhiJoinState);

    // Can be called with or without a session. So construct required non-session data and spread on session data if it exists later.
    const tealiumOptions = {
      page_name: `${capitalizeFirstCharacter(tealiumBusinessSegment.name)} - Quote - ${currentPage.name}`,
      page_type: 'quote_health',
      site_name: tealiumBusinessSegment.siteName,
      site_section: `${capitalizeFirstCharacter(tealiumBusinessSegment.name)} - Quote`
    };

    let sessionTrackingData;

    // Now check for session data. If we have session data, construct the necessary data
    if (sessionState) {
      sessionTrackingData = stringlyTypeObjectValues({
        policy_health_hospital: getHospitalProductName(arhiJoinState) || '',
        policy_health_hospital_id: getHospitalId(arhiJoinState) || '',
        policy_health_hospital_excess: getExcess(arhiJoinState) || '',
        policy_health_extras: getExtrasProductName(arhiJoinState) || '',
        policy_health_extras_id: getExtrasId(arhiJoinState) || '',
        policyholder_address_state: getAddressState(arhiJoinState) || '',
        policyholder_scale: getScale(arhiJoinState) || '',
        policyholder_name_first: getPolicyHolderFirstName(arhiJoinState) || '',
        policyholder_birthmonth: getItemsFromDate(getPolicyHolderDob(arhiJoinState), 'month') || '',
        policyholder_birthyear: getItemsFromDate(getPolicyHolderDob(arhiJoinState), 'year') || '',
        policyholder_phone: getPhoneNumber(arhiJoinState) || '',
        policyholder_email: getPolicyHolderEmail(arhiJoinState) || ''
      });
    }

    // Now merge the data and send to tealium
    const finalTrackingData = {
      ...tealiumOptions,
      ...sessionTrackingData,
      ...getCommonSessionAttributes(arhiJoinState)
    };

    dispatch(trackPageView(finalTrackingData));
  };

/*
 * track send quote form submit. This event is to be fired even if session update fails.
 * because of this, we will use the form values directly instead of from the user's session.
 * this is also how old quote worked.
 */
export const trackJoinHealthFunnelSendQuoteSubmit = (sendQuoteFormValues: SendQuoteFormValues) => async (dispatch) => {
  // We use the user's product offer code (to match old quote, this is the promocode value, not the actual whics id)
  const trackingData = stringlyTypeObjectValues({
    tealium_event: 'web_send_quote',
    event_name: 'web_send_quote',
    policyholder_name_first: sendQuoteFormValues['send-quote-firstName'],
    policyholder_name_last: sendQuoteFormValues['send-quote-lastName'],
    policyholder_email: sendQuoteFormValues['send-quote-email'],
    customer_dob: sendQuoteFormValues['send-quote-dateOfBirth'],
    policyholder_phone: sendQuoteFormValues['send-quote-phoneNumber'],
    policy_health_fund: 'arhi'
  });

  dispatch(trackEvent(trackingData.event_name, trackingData));
};

/*
 * track when 'Download fact sheet' links are used on the cover summary page and as such.
 */
export const trackProductSummaryFactSheetClick =
  ({product, excess, type}: OnFactSheetClickArgs) =>
  async (dispatch, getState) => {
    const arhiJoinState: ARHIJoinState = getState();

    const trackingData = stringlyTypeObjectValues({
      tealium_event: 'link',
      event_name: 'link_click',
      event_label: 'Download fact sheet - ARHI cover summary',
      policy_health_fund: 'arhi',
      product_name: product.name,
      product_pcat_id: product.pcatId,
      product_type: product.type,
      ...(type === 'hospital' && {
        product_excess: excess
      })
    });
    dispatch(trackEvent(trackingData.event_name, {...trackingData, ...getCommonSessionAttributes(arhiJoinState)}));
  };

/*
 * track when the cover summary page is reached
 */
export const trackJoinHealthFunnelProductSummary =
  ({hospitalProduct, extrasProduct}: OnCoverSummaryEventPayload) =>
  async (dispatch, getState) => {
    const arhiJoinState: ARHIJoinState = getState();

    const trackingData = stringlyTypeObjectValues({
      tealium_event: 'cover_summary',
      event_name: 'cover_summary',
      event_label: 'ARHI cover summary',
      policy_health_fund: 'arhi',
      hospital_product_name: hospitalProduct?.name,
      hospital_product_pcat_id: hospitalProduct?.pcatId,
      extras_product_name: extrasProduct?.name,
      extras_product_pcat_id: extrasProduct?.pcatId
    });
    dispatch(trackEvent(trackingData.event_name, {...trackingData, ...getCommonSessionAttributes(arhiJoinState)}));
  };

// Not a fan of this but following the same structure as all of our thunks dispatched via the redux connectors
export type TrackJoinHealthFunnelWelcomeSubmitThunk = (tealiumBusiness: TealiumBusiness) => void;
export type TrackJoinHealthFunnelSendQuoteSubmitThunk = (sendQuoteFormValues: SendQuoteFormValues) => void;
